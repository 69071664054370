<template>
  <div class="container-page">
    <h2 class="title title--h2">Запись перечня односторонних мер нетарифного регулирования</h2>
    <div>
      <div class="form-group required">
        <ComponentInput
          label="Наименование нетарифной меры"
          :select2Settings="select2Settings"
          :select2Options="measures"
          v-model="card.measureId"
          required
          type="select2"
        />
      </div>
      <div class="row">
        <div class="form-group required col-6">
          <ComponentInput
            label="Тип торговой операции"
            :select2Settings="select2Settings"
            :select2Options="tradingOperations"
            v-model="card.tradingOperationId"
            required
            type="select2"
          />
        </div>
        <div class="form-group required col-6">
          <ComponentInput
            label="Страна"
            :select2Settings="select2Settings"
            :select2Options="countries"
            v-model="card.countryId"
            required
            type="select2"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group required col-6">
          <ComponentInput
            label="Страновой охват"
            :select2Settings="select2Settings"
            :select2Options="countryCoverages"
            v-model="card.countryCoverageId"
            required
            type="select2"
          />
        </div>
      </div>
      <div class="form-group disabled-select required">
        <ComponentInput
          label="Товар"
          placeholder="Введите название товара"
          v-model="card.tnvedAlias"
          :maxLength="225"
          required
        />
      </div>
      <div class="form-group" style="position: relative">
        <ComponentInput
          v-model="card.tnvedCodes"
          type="textarea"
          label="Код ТН ВЭД ЕАЭС"
          placeholder="Введите коды ТН ВЭД ЕАЭС, каждый с новой строки, или выберите в каталоге"
        />
        <i class="fas fa-search catalog-icon" title="Найти в каталоге" @click="openTnvedCatalog"></i>
      </div>
      <div class="form-group required">
        <ComponentInput
          label="Отрасль"
          :select2Settings="select2SettingsMulti"
          :select2Options="industries"
          v-model="card.unilateralNonTrIndustryIds"
          type="select2"
          multiple
          required
        />
      </div>
      <div class="row">
        <div class="form-group col-6">
          <div class="form-label">Дата начала действия</div>
          <DateComponent v-model="card.periodDateFrom" :clearable="true" />
        </div>
        <div class="form-group col-6">
          <div class="form-label">Дата окончания действия</div>
          <DateComponent v-model="card.periodDateTo" :clearable="true" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <ComponentInput
            v-model="card.legalActs"
            type="textarea"
            label="Правовой акт"
            placeholder="Введите правовые акты, каждый с новой строки"
          />
        </div>
      </div>
      <div class="form-group">
        <ComponentInput v-model="card.comment" type="textarea" label="Комментарии" />
      </div>
      <div class="form-group">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Действующие</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" @click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" @click="close" />
    </div>
  </div>
  <ModalComponent v-model="tnvedModal" title="Выбор товара" @close="closeTnvedCatalog">
    <CatalogSelector
      v-bind:selected-ids="productIds"
      route="nsitnved"
      :multiple="true"
      :select-leafs-only="true"
      :return-codes="true"
      @select="updateTnved"
      @close="closeTnvedCatalog"
    ></CatalogSelector>
  </ModalComponent>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent';

  import CountryApi from '@/modules/nsi/api/country';
  import NsiApi from '@/modules/nsi/api/nsi';
  import CatalogSelector from '@/modules/nsi/components/CatalogSelector';

  import Api from '../api/unilateralnontr';

  export default {
    name: 'UnilateralNonTrCardOdnostoron',
    components: { ModalComponent, CatalogSelector, ButtonStock, ComponentInput, DateComponent },
    props: {
      isSecond: Boolean,
    },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          id: 0,
          unilateralNonTrIndustryIds: [],
        },
        measures: [],
        countryCoverages: [],
        tradingOperations: [],
        countries: [],
        tnvedModal: false,
        productIds: [],
        industries: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.card = response.data;
            this.card.isSecond = this.isSecond;
          });
        } else this.card.isSecond = this.isSecond;
      },
      loadDictionaries() {
        Utils.searchNsi('NonTariffMeasures', {})
          .then((response) => {
            this.measures = response.data;
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        NsiApi.search('nsitradingoperation', this.defaultRequest)
          .then((response) => {
            this.tradingOperations = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        NsiApi.search('nsiindustry', this.defaultRequest)
          .then((response) => {
            this.industries = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        Utils.loadSelectOptions('countryCoverages', this.countryCoverages, true);
        CountryApi.search(this.defaultRequest)
          .then((response) => {
            this.countries = response.data.items.map((x) => {
              return { text: x.nameFull || x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        Api.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({
                name: 'UnilateralNonTrCardOdnostoron',
                params: { id: this.card.id, action: 'edit' },
              });
            } else {
              this.$router.push({ name: 'UnilateralNonTrRegistryOdnostoron', params: { isSecond: this.isSecond } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      openTnvedCatalog() {
        this.productIds = [];
        this.tnvedModal = true;
      },
      updateTnved(selectedIds) {
        if (!this.card.tnvedCodes) {
          this.card.tnvedCodes = '';
        }
        this.card.tnvedCodes = this.card.tnvedCodes + (this.card.tnvedCodes ? '\n' : '') + selectedIds.join('\n');
        this.closeTnvedCatalog();
      },
      closeTnvedCatalog() {
        this.tnvedModal = false;
      },
      close() {
        if (this.isSecond) this.$router.push({ name: 'UnilateralNonTrRegistryOdnostoron' });
        else this.$router.push({ name: 'UnilateralNonTrRegistry' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  .col-tnved {
    width: 25%;
  }

  .col-rate {
    width: 10%;
  }

  .stockTable {
    .catalog-icon {
      top: 18px;
    }
  }
</style>
